import React from 'react'
import { Trade, TradeType } from '@nerveglobal/greenhouse-one-sdk'
import { Card, CardBody, Text } from '@nerveglobal/greenhouse-one-uikit'
import styled from 'styled-components'
import useI18n from 'hooks/useI18n'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../ColumnSwapPage'
import QuestionHelper from '../QuestionHelper'
import QuestionHelper2 from '../QuestionHelperClean'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'


const CardKit = styled(Card)`
  margin: 2rem auto 0 auto;
  width: 450px;
  background-color: #FFFFFF;

  @media (max-width: 450px) {
    margin: 2rem auto 0 auto;
    width: 100%;
  }
`

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const TranslateString = useI18n()

  return (
    <CardKit>
      <CardBody>
      <RowBetween>
        <RowFixed>
            <Text fontSize="14px" fontWeight="600" >
              {isExactIn ? TranslateString(1210, 'Minimum received') : TranslateString(220, 'Maximum sold')}
            </Text>
            <QuestionHelper
              text={TranslateString(
                202,
                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.'
              )}
            />
            </RowFixed>
            <Text text-align="right" fontSize="14px" fontWeight="600" >
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                  '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                  '-'}
            </Text>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px" fontWeight="600" >{TranslateString(226, 'Price Impact')}</Text>
            <QuestionHelper
              text={TranslateString(
                224,
                'The difference between the market price and estimated price due to trade size.'
              )}
            />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        <RowBetween>
        <RowFixed>
            <Text fontSize="14px" fontWeight="600" >{TranslateString(228, 'Trading Fee')}</Text>
            <QuestionHelper
              text={TranslateString(
                230,
                'For each trade a fee is paid.'
              )}
            />
            </RowFixed>
          <Text fontSize="14px" fontWeight="600" >
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        
        </RowBetween>
      </CardBody>
    </CardKit>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()
  const TranslateString = useI18n()
  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <AutoColumn style={{ width: "450px", margin: '0 auto 0 auto' }}>
                <RowFixed>
                  <Text color="#FFFFFF" fontSize="14px" fontWeight="600" >Route</Text>
                  <QuestionHelper2
                    text={TranslateString(
                      999,
                      'Routing through these tokens resulted in the best price for your trade.'
                    )}
                  />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
