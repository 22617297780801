import { MenuEntry } from '@nerveglobal/greenhouse-one-uikit'

export const config: MenuEntry[] = [
  {
    label: "Exchange",
    href: "https://harmony.trade.greenhousedex.com/",
  },
  {
    label: "Farms",
    href: "https://harmony.greenhousedex.com/farms",
  },
  {
    label: "Stake",
    href: "https://harmony.greenhousedex.com/stake",
  },
];
export const sideconfig: MenuEntry[] = [
];

export default config