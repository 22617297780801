import { ChainId, JSBI, Percent, Token, WETH } from '@nerveglobal/greenhouse-one-sdk'

export const ROUTER_ADDRESS = '0x570a59e90245a7b0C742eBE4Fa3215d71a0Fd418' // dino
// '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506' // sushi
// '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff' // quickswap

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const CAKE = new Token(
  ChainId.MAINNET,
  '0xF84B92738Cb51d39Ec5a4EA03F19BfdAD545a8aE',
  18,
  'DINO',
  'DinoSwap Token'
)
export const WONE = new Token(ChainId.MAINNET, '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a', 18, 'WONE', 'Wrapped ONE')
export const WBNB = new Token(ChainId.MAINNET, '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a', 18, 'WONE', 'Wrapped ONE')
export const GREEN = new Token(ChainId.MAINNET, '0xbfC044a234E45412eCfeC2Cd6aAe2dd0C083a7cd', 18, 'GREEN', 'Greenhouse')
export const USDC = new Token(ChainId.MAINNET, '0x985458E523dB3d53125813eD68c274899e9DfAb4', 6, 'USDC', 'USD Coin')
export const USDT = new Token(ChainId.MAINNET, '0x3C2B8Be99c50593081EAA2A724F0B8285F5aba8f', 6, 'USDT', 'Tether USD')
export const ETH = new Token(
  ChainId.MAINNET,
  '0x6983D1E6DEf3690C4d616b13597A09e6193EA013',
  18,
  'ETH',
  'Wrapped Ethereum Token'
)

//

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], USDC, USDT, ETH, WONE, GREEN],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], USDC, USDT, WONE, GREEN],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], USDC, USDT, GREEN],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0xF84B92738Cb51d39Ec5a4EA03F19BfdAD545a8aE', 18, 'DINO', 'DinoSwap Token'),
      new Token(ChainId.MAINNET, '0xcf664087a5bb0237a0bad6742852ec6c8d69a27a', 18, 'WONE', 'Wrapped ONE'),
    ],
    /* [BUSD, USDT],
    [DAI, USDT], */
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
